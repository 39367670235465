.post-image {
  background-color: rgb(245, 245, 245);
  text-align: center;
  border-radius: 4px solid pearl;
}
#dropdown-option::after {
  display: none;
}
.dropdown-menu {
  padding: 0.5px;
  text-align: left;
}

.card-footer {
  display: flex;
}

#comment_icon {
  margin-left: auto;
  order: 2;
  margin-right: 15px;
}
