.filter-container {
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 40px;
}

.filter-button {
  width: 100%;
  margin-bottom: 3px;
  margin-top: 3px;
  margin-left: 3px;
  background-color: white;
  color: black;
}

.reset-button {
  background-color: white;
  color: brown;
  font-weight: bold;
  margin-bottom: 3px;
  width: 100%;
  margin-top: 3px;
  border: 1px solid brown;
}

.reset-button:hover {
  background-color: brown;
  color: white;
}

.post-btn {
  width: 80%;
  color: #1b8474;
  background-color: white;
  margin-top: 15px;
}

.modal-panel {
  text-align: right !important;
}

.post-close-button {
  background-color: white;
  color: red;
  margin-top: 15px;
  width: 80%;
}

.remove-btn {
  margin-top: 15px;
  width: 80%;
}

.search-box {
  margin-bottom: 3px;
}

/* .RMM__container {
  text-align: right !important;
} */

.section {
  margin-bottom: 20px;
}

.custom-file-label::after {
  content: "Browse";
}

.post-image {
  background-color: rgb(245, 245, 245);
  text-align: center;
  border-radius: 4px solid pearl;
}
.RMM__placeholder {
  top: 90px !important;
}

@media (min-width: 601px) and (max-width: 1024px) {
  .RMM__placeholder {
    left: 48px !important;
  }
}

@media (min-width: 1281px) {
  .RMM__placeholder {
    left: 400px !important;
  }
}

.form-label {
  margin-top: 15px;
  display: inline-block;
}
