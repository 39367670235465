.account-nav-button-container {
  position: relative;
  margin-top: -70px;
  height: 56px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.2);
  padding-right: 10px; /* Add padding to create space */
}

.profile-panel {
  margin-right: 10px; /* Updated */
  color: yellow;
  line-height: 56px;
}

.circle-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid white;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  color: white;
}
