.modal-custom .modal-dialog {
  width: 100%;
  margin: 0;
}

.modal-custom .modal-content {
  width: 100%;
}

.purple {
  color: purple;
}
