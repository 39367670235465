.comment-thread {
  margin-top: 15px;
  margin-bottom: 5px;
  border: 1px solid #e0e0e0;
  box-shadow: "0px 2px 2px rgba(0; 0; 0; 0.1)";
  padding: 10px;
  border-radius: 4px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.astro-img {
  border-radius: "100%";
  border: "1px solid #e9ecef";
}

.comment-username {
  color: #198474;
}

.comment-time {
  color: grey;
  font-size: 0.8rem;
  margin-top: -0.5rem;
}

.comment-body {
  margin-top: -0.5rem;
  margin-bottom: 0;
  word-break: "break-word";
}

.reaction-area {
  display: flex;
  justify-content: space-between;
}
