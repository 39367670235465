.post-image {
  background-color: rgb(245, 245, 245);
  text-align: center;
  border-radius: 4px solid pearl;
}

.post_profileImg {
  margin-right: 1px;
  border: 1px solid #1b8474;
  border-radius: 50%;
}

.pd_subtitle {
  margin-top: 2px;
  padding-left: 36px;
  color: gray;
  font-size: 0.85rem;
}

.pd_title {
  font-size: 1.1rem;
}

.pd_card {
  text-align: left;
}

.pd_delete_edit {
  float: right;
}

.no_comments {
  text-align: center;
  margin-top: 20px;
}
