.profile-cover-container {
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-container {
  position: absolute;
  top: 90%;
  left: 10px;
  border: 5px solid #fff;
  border-radius: 50%;
  background-color: grey;
  z-index: 1;
}

.profile-img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
}

.carousel {
  background-color: black;
}
